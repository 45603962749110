import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import type { PageOptions } from 'src/models/pageOptions';
import {SERVER_BASE} from "../CMQ";
import type {ProjectPosts} from "../models/projectPost";

interface ProjectsState extends ProjectPosts {
    loading: boolean;
    error: string | null;
}

// Stato iniziale
const initialState: ProjectsState = {
    posts: [],
    loading: false,
    error: null,
};

// Thunk per ottenere le impostazioni del sito dall'API
export const fetchProjects = createAsyncThunk<ProjectPosts>('projects/fetchProjects', async () => {
    const response = await axios.get(`${SERVER_BASE}/projects/all`);
    return response.data;
});

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.posts = action.payload.posts;
                state.loading = false;
            })
            .addCase(fetchProjects.rejected, (state) => {
                state.error = 'Errore nel caricamento dei progetti.';
                state.loading = false;
            });
    },
});

export default projectsSlice.reducer;
