import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {SERVER_BASE} from "../CMQ";
import type {TeamMembers} from "../models/teamMemberPost";

interface TeamMemberState extends TeamMembers {
    loading: boolean;
    error: string | null;
}

// Stato iniziale
const initialState: TeamMemberState = {
    posts: [],
    loading: false,
    error: null,
};

// Thunk per ottenere le impostazioni del sito dall'API
export const fetchTeamMembers = createAsyncThunk<TeamMembers>('teamMembers/fetchTeamMembers', async () => {
    const response = await axios.get(`${SERVER_BASE}/team-members/all`);
    return response.data;
});

const teamMembersSlice = createSlice({
    name: 'teamMembers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTeamMembers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTeamMembers.fulfilled, (state, action) => {
                state.posts = action.payload.posts;
                state.loading = false;
            })
            .addCase(fetchTeamMembers.rejected, (state) => {
                state.error = 'Errore nel caricamento dei membri del team.';
                state.loading = false;
            });
    },
});

export default teamMembersSlice.reducer;
