enum TargetContent {
    WORK = 1,
    BENEFIT = 2,
    SALOTTO = 3,
    PEOPLE = 4,
    SINGLE_PROJECT = 5,
    DEFAULT = -1
}

export default TargetContent;
