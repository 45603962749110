import { createContext, useState } from "react";
import WorkListType from "../enum/WorkListType";

type WorkListContextType = {
    activeType: WorkListType;
    setActiveType: (targetType: WorkListType) => void;
}

export const WorkListContext = createContext<WorkListContextType>({} as WorkListContextType);

export const WorkListProvider = ({ children }) => {

    const [activeType, setActiveType] = useState<WorkListType>(WorkListType.IMAGE);

    return (
        <WorkListContext.Provider value={{ activeType, setActiveType }}>
            {children}
        </WorkListContext.Provider>
    )
}
