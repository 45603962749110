import React from 'react';
import './fonts/Acidic.woff';
import './fonts/Acidic.woff2';
import './App.scss';
import { useRoutes } from "react-router";
import routes from "./router/router";

export const SERVER_BASE = "https://cmq-wp.s4staging.it/wp-json/wp/v2";

function CMQ() {

  const content = useRoutes(routes);

  return (
    <div className={"App"} id={"App"}>
      {content}
    </div>
  );
}

export default CMQ;
