import { createContext, useState } from "react";
import history from "history/browser";

type NavigationContextType = {
    workSlug: string;
    resetWorkSlug: () => void,
    setWorkSlug: (targetWorkSlug: string) => void
}

export const NavigationContext = createContext<NavigationContextType>({} as NavigationContextType);

export const NavigationProvider = ({ children }) => {

    const [workSlug, setWorkSlug] = useState<string>("");

    const resetWorkSlug = () => {

        setWorkSlug("");
        history.replace("/work");
    }

    return (
        <NavigationContext.Provider value={{ setWorkSlug, resetWorkSlug, workSlug }}>
            {children}
        </NavigationContext.Provider>
    )
}
