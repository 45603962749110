import {combineReducers} from "@reduxjs/toolkit";
import siteOptionsReducer from "../slices/siteOptions";
import teamMembersReducer from "../slices/teamMembers";
import pageOptionsReducer from "../slices/pageOptions";
import projectsReducer from "../slices/projects";
import exhibitionsReducer from "../slices/exhibitions";

const rootReducer = combineReducers({
    siteOptions: siteOptionsReducer,
    pageOptions: pageOptionsReducer,
    exhibitions: exhibitionsReducer,
    projects: projectsReducer,
    teamMembers: teamMembersReducer
})

export default rootReducer;
