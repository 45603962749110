import {lazy, Suspense} from "react";
import SuspenseLoader from "../components/SuspenseLoader";
import BaseLayout from "../layouts/BaseLayout";
import TargetContent from "../enum/TargetContent";

const Loader = (Component) => props => (
    <Suspense fallback={<SuspenseLoader/>}>
        <Component {...props} />
    </Suspense>
)

const Home = Loader(lazy(() => import('src/content/Home')));

const routes = [
    {
        path: '*',
        element: (
            <BaseLayout/>
        )
    },
    {
        path: '/',
        element: (
            <BaseLayout/>
        ),
        children: [
            {
                path: '/',
                element: <Home/>
            },
            {
                path: '/benefit',
                element: <Home startPage={TargetContent.BENEFIT}/>
            },
            {
                path: '/people',
                element: <Home startPage={TargetContent.PEOPLE}/>
            },
            {
                path: '/work',
                element: (<Home startPage={TargetContent.WORK}/>),
                children: [
                    {
                        path: ':slug',
                        element: <Home startPage={TargetContent.SINGLE_PROJECT} />
                    }
                ]
            },
            {
                path: '/salotto',
                element: <Home startPage={TargetContent.SALOTTO}/>
            }
        ]
    }
]

export default routes;
